<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">403</h1>
          <h4 class="pt-3">Oops! You don't have permission.</h4>
          <p class="text-muted ">The page you are looking for was cannot reach.</p>
          <CRow>
            <CCol sm="12">
              <p class="text-muted "><a href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1" target="_blank"><i class="fas fa-chevron-right"></i>{{" "}}ขอความช่วยเหลือ</a></p>
            </CCol>
            <CCol sm="12">
              <p class="text-muted "><a href="https://exam-checkin.tlic.cmu.ac.th/"><i class="fas fa-chevron-right"></i>{{" "}}ไปที่ Exam-checkin (นักศึกษา)</a></p>
            </CCol>
            <CCol sm="12">
              <p class="text-muted "><a href="/signout" class="text-danger"><i class="fas fa-chevron-right"></i>{{" "}}ออกจากระบบเพื่อเปลี่ยนบัญชี</a></p>
            </CCol>
          </CRow>
        </div>
        <!-- <CInput
          class="mb-3"
          placeholder="What are you looking for?"
        >
          <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
          <template #append>
            <CButton color="info">Search</CButton>
          </template>
        </CInput> -->
      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'PageNoPermission',
  created() {
  },
}
</script>
